import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./fonts/AppleGothic.ttf";
import "./fonts/Helvetica-Light.ttf";
import "./fonts/Itim-Regular.ttf";
import "./fonts/Poppins-Light.ttf";
import "./fonts/Poppins-Bold.ttf";
import "./fonts/Poppins-SemiBold.ttf";
import Activity from "./scenes/Activity";
import Footer from "./scenes/components/layout/Footer";
import NavBar from "./scenes/components/layout/NavBar";
import DeleteAccount from "./scenes/DeleteAccount";
import Download from "./scenes/Download";
import EmailPreferences from "./scenes/EmailPreferences";
import FAQ from "./scenes/FAQ";
import Home from "./scenes/Home";
import Invite from "./scenes/Invite";
import Privacy from "./scenes/Privacy";
import Terms from "./scenes/Terms";
import Security from "./scenes/Security";
import Pricing from "./scenes/Pricing";

ReactGA.initialize("G-0Y8VX1W3PM");

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/security" element={<Security />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/email-preferences" element={<EmailPreferences />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/download" element={<Download />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
