import React from "react";

import ReactGA from "react-ga4";
import ButtonMailto from "../components/ButtonMailto";

import security from "../../img/security.png";
import cryptography from "../../img/cryptography.png";
import audit from "../../img/audit.png";
import dataTransmission from "../../img/data-transmission.png";
import dataSecurity from "../../img/data-security.png";
import peopleSecurity from "../../img/people-security.png";
// import "./index.css";

function Security() {
  return (
    <div className="App">
      <section>
        <div className="blue-section">
          <div className="value-prop">
            <h1 className="h1">
              <p
                style={{
                  fontSize: "2rem",
                  fontFamily: "Poppins-Light",
                  color: "#333",
                }}
              >
                How We Keep Your Data Secure
              </p>
              <p style={{ fontSize: "1.25rem" }}>
                At Farewell Notes, protecting your personal asset information is
                our top priority. We implement multiple layers of security to
                ensure your data remains safe both at rest and in transit.
              </p>
            </h1>
          </div>
          <div className="security-spacer"></div>
          <div className="phone-image">
            <img className="security" src={security} alt="Assets" />
          </div>
        </div>
      </section>
      <section>
        <div className="white-section">
          <div className="value-prop">
            <h1 className="h1">
              <p
                style={{
                  fontSize: "2rem",
                  fontFamily: "Poppins-Light",
                  color: "#333",
                }}
              >
                Securing Data at Rest
              </p>
              <p style={{ fontSize: "1.25rem" }}>
                We utilize AES-256 encryption, one of the most robust industry
                standards, to encrypt all stored sensitive data. Even if
                unauthorized access were somehow gained, the data remains
                unreadable without the correct encryption keys. Our encryption
                keys are securely managed to ensure they are protected at scale
                and handled with the highest level of security. Additionally,
                strict access controls and audit logging are in place to
                maintain data security and monitor access.
              </p>
            </h1>
          </div>
          <div className="security-spacer"></div>
          <div className="phone-image">
            <img className="security" src={dataSecurity} alt="Assets" />
          </div>
        </div>
      </section>
      <section>
        <div className="blue-section">
          <div className="value-prop">
            <h1 className="h1">
              <p
                style={{
                  fontSize: "2rem",
                  fontFamily: "Poppins-Light",
                  color: "#333",
                }}
              >
                Securing Data in Transit
              </p>
              <p style={{ fontSize: "1.25rem" }}>
                To protect your data as it moves between your device and our
                servers, we enforce TLS (Transport Layer Security) encryption.
                This prevents interception or tampering by ensuring all
                communication is securely encrypted. Whether you're accessing
                your information, updating assets, or sharing with
                beneficiaries, your data remains protected every step of the
                way.
              </p>
            </h1>
          </div>
          <div className="security-spacer"></div>
          <div className="phone-image">
            <img className="security" src={dataTransmission} alt="Assets" />
          </div>
        </div>
      </section>
      <section>
        <div className="white-section">
          <div className="value-prop">
            <h1 className="h1">
              <p
                style={{
                  fontSize: "2rem",
                  fontFamily: "Poppins-Light",
                  color: "#333",
                }}
              >
                Access Control & Monitoring
              </p>
              <p style={{ fontSize: "1.25rem" }}>
                Security doesn't stop at encryption. We enforce strict access
                controls and use detailed audit logging to track and monitor all
                interactions with your encrypted data. This ensures that only
                authorized users - such as you and your designated beneficiaries
                - can access the information you've chosen to share.
              </p>
            </h1>
          </div>
          <div className="security-spacer"></div>
          <div className="phone-image">
            <img className="security" src={audit} alt="Assets" />
          </div>
        </div>
      </section>
      <section>
        <div className="blue-section">
          <div className="value-prop">
            <h1 className="h1">
              <p
                style={{
                  fontSize: "2rem",
                  fontFamily: "Poppins-Light",
                  color: "#333",
                }}
              >
                Your Role in Security
              </p>
              <p style={{ fontSize: "1.25rem" }}>
                While we implement industry-leading protections, security is
                also a shared responsibility. Choosing a strong password and
                keeping it private is essential to ensuring your data remains
                protected. Farewell Notes provides a secure and structured way
                to share access with beneficiaries, giving you peace of mind
                that your legacy is both safe and accessible to those who need
                it.
              </p>
            </h1>
          </div>
          <div className="security-spacer"></div>
          <div className="phone-image">
            <img className="security" src={peopleSecurity} alt="Assets" />
          </div>
        </div>
      </section>
      <section>
        <div>
          <div className="value-prop">
            <h1 style={{ marginBottom: 0, marginTop: 100, fontSize: "1.25rem" }} className="h2">
              If you have any questions about security, please reach out to us
              via email:{" "}
              <ButtonMailto
                label="support@farewellnotes.com"
                mailto="mailto:support@farewellnotes.com"
              />
              .
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Security;
