import React from "react";
import "./index.css";

const Pricing = () => {
  const plans = [
    {
      name: "Start",
      price: "$0",
      features: [
        "Manage and update your personal assets for 30 days",
        "Create and personalize heartfelt farewell messages for loved ones for 30 days",
        "Assign and update beneficiaries for 30 days",
        "Retain your assets for up to 90 days after your trial period ends",
        "Access your benefactor's asset details without restrictions.  You won't need to make a purchase to access your loved ones' asset information!",
      ],
      buttonText: "Get Started",
      popular: false,
    },
    {
      name: "Premium",
      price: "$9.99",
      features: [
        "Manage and update your personal assets without any limitations",
        "Continue creating and customizing heartfelt farewell messages for your loved ones",
        "Continue assigning and updating beneficiaries to ensure your final wishes are honored",
        "Extended asset retention for up to 3 years after your subscription ends, ensuring long-term access for your beneficiaries",
        "Enjoy unlimited updates to keep your information current and accurate",
      ],
      buttonText: "Get Started",
      popular: false,
    },
  ];

  return (
    <div className="pricing-wrapper">
      <h2 className="pricing-title">Our Pricing Plans</h2>
      <p className="pricing-subtitle">
        Choose a plan that works best for you and your needs.
      </p>

      <div className="pricing-cards">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`pricing-card ${plan.popular ? "popular" : ""}`}
          >
            {plan.popular && <div className="badge">Most Popular</div>}
            <h3 className="plan-name">{plan.name}</h3>
            <p className="plan-price">
              {plan.price}
              <span className="plan-duration">/year</span>
            </p>
            <ul className="features-list">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="feature">
                  <span className="feature-check">✔</span> {feature}
                </li>
              ))}
            </ul>
            {/* <button className="plan-button">{plan.buttonText}</button> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
